// Types and Interfaces
interface MeetingRecordingState {
    currentSessionId: string | null;
    recording: boolean;
    chunkSet: Blob[];
    chunkNumber: number;
    mediaRecorder: MediaRecorder | null;
    audioStream: MediaStream | null;
    isRecording: boolean;
    seconds: number;
}

interface UIElements {
    recordButton: HTMLButtonElement;
    timerElement: HTMLElement;
    progressBarContainer: HTMLElement;
    progressBar: HTMLElement;
    animationContainer: HTMLElement;
    summaryElement: HTMLElement;
    copyButton: HTMLButtonElement;
    copySessionButton: HTMLButtonElement;
    copyTranscriptionButton: HTMLButtonElement;
    retryButton: HTMLButtonElement;
    statusElement: HTMLElement;
    promptSection: HTMLElement;
    promptInput: HTMLTextAreaElement;
    promptResponses: HTMLElement;
    sendPromptButton: HTMLButtonElement;
    mainHeading: HTMLElement;
    leftColumn: HTMLElement;
    audioSource: HTMLSelectElement;
    audioVisualizer: HTMLCanvasElement;
    newMeetingButton: HTMLButtonElement;
}

interface Intervals {
    timer?: number;
    heartbeat?: number;
    summaryPolling?: number;
    progressUpdater?: number;
}

interface SummaryResponse {
    status: 'complete' | 'processing' | 'error';
    summary?: string;
    session_id?: string;
    error?: string;
}

interface ProgressAnimation {
    complete: () => Promise<void>;
}

class MeetingRecorder {
    private state: MeetingRecordingState;
    private ui: UIElements;
    private wakeLock: any | null;
    private intervals: Intervals;
    private maxRecordingDuration: number = 7200; // 2 hours in seconds
    private supportedMediaFormat: string | null = null;
    private animationFrames: string[] = [
        '(^・ω・^)',
        '(^・ω・^)ノ',
        '(^・ω・^)ﾉ',
        '(^・ω・^)/',
        '(^・ω・^)',
        '(^・ω・^)\\',
        '(^・ω・^)ﾉ',
        '(^・ω・^)ノ'
    ];
    private progressAnimation: ProgressAnimation | null = null;
    private isIOS: boolean;
    private isSafari: boolean;
    private audioContext: AudioContext | null = null;
    private analyser: AnalyserNode | null = null;
    private animationFrameId: number | null = null;

    constructor() {
        this.isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
        this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        this.initializeState();
        this.checkSupportedFormats();
        
        // Wait for DOM to be loaded before binding UI elements
        if (document.readyState === 'loading') {
            document.addEventListener('DOMContentLoaded', () => this.initializeApp());
        } else {
            this.initializeApp();
        }
    }

    private initialize(): void {
        this.bindUIElements();
        this.loadAudioDevices();
        this.setupEventListeners();
        this.updateUI();
        
        // Check for session ID in URL path
        const pathParts = window.location.pathname.split('/');
        const sessionId = pathParts[1]; // Get the first part after the /
        
        if (sessionId && sessionId.length > 0) {
            // Remove any trailing slashes
            const cleanSessionId = sessionId.replace(/\/$/, '');
            this.loadSessionFromId(cleanSessionId);
        }
    }

    private async loadAudioDevices(): Promise<void> {
        try {
            // Only enumerate devices without requesting microphone access
            const devices = await navigator.mediaDevices.enumerateDevices();
            const audioInputs = devices.filter(device => device.kind === 'audioinput');
            
            console.log('Available audio devices:', audioInputs);
            
            // Clear existing options except the default
            while (this.ui.audioSource.options.length > 1) {
                this.ui.audioSource.remove(1);
            }

            // Update the default option
            const defaultDevice = audioInputs.find(device => device.deviceId === 'default');
            if (defaultDevice) {
                this.ui.audioSource.options[0].text = defaultDevice.label || 'Default Microphone';
            }
            
            // Add available audio inputs (excluding the default device)
            audioInputs
                .filter(device => device.deviceId !== 'default')
                .forEach(device => {
                    const option = document.createElement('option');
                    option.value = device.deviceId;
                    option.text = device.label || `Microphone ${this.ui.audioSource.options.length}`;
                    this.ui.audioSource.appendChild(option);
                });

            // Log the current audio input configuration
            console.log('Current audio configuration:', {
                defaultDevice: defaultDevice?.label || 'Unknown',
                totalDevices: audioInputs.length,
                availableDevices: audioInputs.map(d => ({
                    deviceId: d.deviceId,
                    label: d.label,
                    groupId: d.groupId
                }))
            });
        } catch (error) {
            console.error('Error loading audio devices:', error);
        }
    }

    private async loadSessionFromId(sessionId: string): Promise<void> {
        try {
            // First try to get archived session
            const archivedResponse = await fetch(`/api/archived-session/${sessionId}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },
            });

            if (archivedResponse.ok) {
                const data = await archivedResponse.json();
                
                // Set the session ID since we found a valid session
                this.state.currentSessionId = sessionId;
                
                // Hide the entire left column when loading a session directly
                if (this.ui.leftColumn) {
                    this.ui.leftColumn.style.display = 'none';
                }
                
                if (data.summary) {
                    // Display the summary with markdown parsing
                    this.ui.summaryElement.innerHTML = this.parseMarkdown(data.summary);
                    this.ui.summaryElement.style.display = 'block';
                    this.showCopyButton();
                    
                    // Show prompt section if we have a summary
                    this.ui.promptSection.style.display = 'block';
                    
                    // Show the new meeting button
                    this.ui.newMeetingButton.style.display = 'flex';
                }
                
                // Update status message but place it somewhere visible
                this.ui.statusElement.style.position = 'absolute';
                this.ui.statusElement.style.top = '20px';
                this.ui.statusElement.style.left = '20px';
                this.ui.statusElement.textContent = `Loaded session: ${sessionId}`;
                
                return;
            }

            // If not found in archive, try active session
            const activeResponse = await fetch(`/api/active-session/${sessionId}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },
            });

            if (!activeResponse.ok) {
                const errorData = await activeResponse.json().catch(() => ({}));
                throw new Error(errorData.detail || 'Session not found');
            }

            const data = await activeResponse.json();
            if (data.summary) {
                this.displaySummary(data.summary);
                this.showCopyButton();
            } else if (data.status === 'recording' || data.status === 'processing') {
                this.handleError('Session is still being processed...', false);
            }
        } catch (error) {
            console.error('Error loading session:', error);
            this.state.currentSessionId = null; // Clear the session ID if there's an error
            this.handleError('Failed to load session: ' + (error as Error).message);
        }
    }

    private initializeState(): void {
        this.state = {
            currentSessionId: null,
            recording: false,
            chunkSet: [],
            chunkNumber: 0,
            mediaRecorder: null,
            audioStream: null,
            isRecording: false,
            seconds: 0
        };
        
        this.intervals = {};
        this.wakeLock = null;
    }

    private bindUIElements(): void {
        const elements = {
            recordButton: document.querySelector('#recordButton') as HTMLButtonElement,
            timerElement: document.querySelector('#timer') as HTMLElement,
            progressBarContainer: document.querySelector('#progressBarContainer') as HTMLElement,
            progressBar: document.querySelector('#progressBar') as HTMLElement,
            animationContainer: document.querySelector('#animationContainer') as HTMLElement,
            summaryElement: document.querySelector('#summary') as HTMLElement,
            copyButton: document.querySelector('#copyButton') as HTMLButtonElement,
            copySessionButton: document.querySelector('#copySessionButton') as HTMLButtonElement,
            copyTranscriptionButton: document.querySelector('#copyTranscriptionButton') as HTMLButtonElement,
            retryButton: document.querySelector('#retryButton') as HTMLButtonElement,
            statusElement: document.querySelector('#status') as HTMLElement,
            promptSection: document.querySelector('.prompt-section') as HTMLElement,
            promptInput: document.querySelector('#promptInput') as HTMLTextAreaElement,
            promptResponses: document.querySelector('#promptResponses') as HTMLElement,
            sendPromptButton: document.querySelector('#sendPromptButton') as HTMLButtonElement,
            mainHeading: document.querySelector('#main-heading') as HTMLElement,
            leftColumn: document.querySelector('.left-column') as HTMLElement,
            audioSource: document.querySelector('#audioSource') as HTMLSelectElement,
            audioVisualizer: document.getElementById('audioVisualizer') as HTMLCanvasElement,
            newMeetingButton: document.querySelector('#newMeetingButton') as HTMLButtonElement,
        };

        // Check if any elements are null
        const missingElements = Object.entries(elements)
            .filter(([_, el]) => !el)
            .map(([key]) => key);

        if (missingElements.length > 0) {
            console.error('Missing UI elements:', missingElements);
            throw new Error('Required UI elements not found');
        }

        this.ui = elements;
    }

    private setupEventListeners(): void {
        // Recording and prompt controls
        this.ui.recordButton.addEventListener('click', () => this.toggleRecording());
        this.ui.sendPromptButton.addEventListener('click', () => this.handlePrompt());
        
        // Add Enter key handler for prompt input
        this.ui.promptInput.addEventListener('keydown', (event: KeyboardEvent) => {
            // Check if Enter was pressed without Shift (Shift+Enter allows for newlines)
            if (event.key === 'Enter' && !event.shiftKey) {
                event.preventDefault(); // Prevent newline
                this.handlePrompt();
            }
        });

        // Copy buttons
        this.ui.copyButton.addEventListener('click', () => this.handleCopyClick());
        this.ui.copySessionButton.addEventListener('click', () => this.handleCopySessionId());
        this.ui.copyTranscriptionButton.addEventListener('click', () => this.handleCopyTranscription());
        this.ui.retryButton.addEventListener('click', () => this.handleRetryClick());
        
        // New meeting button
        this.ui.newMeetingButton.addEventListener('click', () => {
            window.location.href = '/';
        });
        
        document.addEventListener('visibilitychange', async () => {
            if (this.wakeLock !== null && document.visibilityState === 'visible') {
                await this.acquireWakeLock();
            }
        });

        // Add device change listener
        navigator.mediaDevices.addEventListener('devicechange', () => {
            this.loadAudioDevices();
        });

        // Audio source change no longer triggers preview
        this.ui.audioSource.addEventListener('change', () => {
            console.log('Audio source changed to:', this.ui.audioSource.value);
        });
    }

    private checkSupportedFormats(): void {
        if (MediaRecorder.isTypeSupported('video/webm')) {
            this.log('video/webm is supported');
            this.supportedMediaFormat = 'video/webm';
        } else if (MediaRecorder.isTypeSupported('audio/webm')) {
            this.log('audio/webm is supported');
            this.supportedMediaFormat = 'audio/webm';
        } else if (MediaRecorder.isTypeSupported('video/mp4')) {
            this.log('video/mp4 is supported');
            this.supportedMediaFormat = 'video/mp4';
        } else if (MediaRecorder.isTypeSupported('audio/mp4')) {
            this.log('audio/mp4 is supported');
            this.supportedMediaFormat = 'audio/mp4';
        } else if (MediaRecorder.isTypeSupported('audio/ogg')) {
            this.log('audio/ogg is supported');
            this.supportedMediaFormat = 'audio/ogg';
        } else {
            this.log('No supported media formats found', 'error');
            this.supportedMediaFormat = null;
        }
    }

    private async startRecording(): Promise<void> {
        try {
            await this.resetState();
            await this.acquireWakeLock();

            // Check if getUserMedia is available
            if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
                throw new Error('getUserMedia is not supported in this browser');
            }

            // Request microphone access when starting recording
            try {
                const constraints = {
                    audio: {
                        channelCount: 1,
                        sampleRate: 16000,
                        deviceId: this.ui.audioSource.value ? { exact: this.ui.audioSource.value } : undefined
                    }
                };

                this.state.audioStream = await navigator.mediaDevices.getUserMedia(constraints);
                
                // Set up audio visualization now that we have permission
                await this.setupAudioVisualization(this.state.audioStream);
            } catch (err) {
                console.error('Microphone error:', err);
                this.handleError("Failed to access microphone. Please check your browser settings and try again.", true);
                return;
            }

            // If we got here, we have a valid audio stream
            await this.startNewRecordingSegment();
            this.state.recording = true;
            this.ui.recordButton.textContent = "Stop Recording";
            this.ui.recordButton.classList.add('red-button');
            this.startTimer();
            this.startRecordingTimeout();

        } catch (error) {
            console.error("Error in recording setup:", error);
            this.handleError("An unexpected error occurred while setting up recording.", true);
        }
    }

    private async startNewRecordingSegment(): Promise<void> {
        try {
            if (!this.state.audioStream) {
                throw new Error("No active media stream");
            }

            if (!this.supportedMediaFormat) {
                throw new Error("No supported media format found");
            }

            this.state.mediaRecorder = new MediaRecorder(this.state.audioStream, {
                mimeType: this.supportedMediaFormat,
                audioBitsPerSecond: 128000
            });

            this.log(`New MediaRecorder created with MIME type: ${this.supportedMediaFormat}`);

            this.state.mediaRecorder.ondataavailable = async (event: BlobEvent) => {
                if (event.data.size > 0) {
                    this.log(`Data available for chunk ${this.state.chunkNumber}, size: ${event.data.size} bytes`);
                    await this.sendChunk(event.data, this.state.chunkNumber, !this.state.recording);
                    this.log(`Sent chunk ${this.state.chunkNumber}. Total sent chunks: ${this.state.chunkNumber + 1}`);
                    this.state.chunkNumber++;
                }
            };

            this.state.mediaRecorder.start();
            this.log("Started new recording segment");

            // Schedule the stop of this segment and start of the next one
            setTimeout(() => {
                if (this.state.recording) {
                    this.log("30 seconds elapsed, stopping current segment");
                    if (this.state.mediaRecorder) {
                        this.state.mediaRecorder.stop();
                    }
                    setTimeout(() => {
                        if (this.state.recording) {
                            this.log("Starting next segment");
                            this.startNewRecordingSegment();
                        }
                    }, 0);
                }
            }, 30000); // 30 seconds
        } catch (error) {
            console.error("Error starting new recording segment:", error);
            this.handleError("Failed to start new recording segment. Please try again.", true);
        }
    }

    private async stopRecording(): Promise<void> {
        this.log(`Stopping recording. Sent chunks: ${this.state.chunkNumber}`);
        
        // Force state update regardless of current state
        this.state.recording = false;
        this.updateUI();
        
        // Start progress animation immediately
        this.progressAnimation = this.startProgressUpdates();
        
        try {
            let finalChunkReceived = false;
            
            // Set up one-time listener for the final data
            if (this.state.mediaRecorder && this.state.mediaRecorder.state !== 'inactive') {
                await new Promise<void>((resolve, reject) => {
                    const timeoutId = setTimeout(() => {
                        reject(new Error('Final chunk timeout'));
                    }, 5000);

                    const handleFinalData = async (event: BlobEvent) => {
                        try {
                            if (event.data.size > 0) {
                                this.log(`Sending final chunk ${this.state.chunkNumber}, size: ${event.data.size} bytes`);
                                await this.sendChunk(event.data, this.state.chunkNumber, true);
                                this.log(`Final chunk ${this.state.chunkNumber} sent successfully`);
                                finalChunkReceived = true;
                            }
                            clearTimeout(timeoutId);
                            resolve();
                        } catch (error) {
                            clearTimeout(timeoutId);
                            reject(error);
                        }
                    };

                    this.state.mediaRecorder!.ondataavailable = handleFinalData;
                    this.state.mediaRecorder!.stop();
                }).catch(error => {
                    this.log(`Warning: ${error.message}`, 'warn');
                });
            }

            // Always clean up audio stream
            if (this.state.audioStream) {
                this.state.audioStream.getTracks().forEach(track => track.stop());
                this.state.audioStream = null;
            }

            this.stopAudioVisualization();
            this.stopTimer();
            
            // If we didn't get a final chunk with data, send an empty one
            if (!finalChunkReceived) {
                this.log('No final chunk received, sending empty chunk');
                const finalChunk = new Blob([], { type: this.supportedMediaFormat || 'video/webm' });
                await this.sendChunk(finalChunk, this.state.chunkNumber, true);
            }
            
            // Start fetching summary with recovery logic
            let retryCount = 0;
            const maxRetries = 3;
            
            while (retryCount < maxRetries) {
                try {
                    await this.fetchSummary();
                    break;
                } catch (error) {
                    retryCount++;
                    this.log(`Error fetching summary (attempt ${retryCount}): ${error.message}`, 'error');
                    if (retryCount < maxRetries) {
                        // Exponential backoff
                        await new Promise(resolve => setTimeout(resolve, 2000 * Math.pow(2, retryCount)));
                    } else {
                        throw error;
                    }
                }
            }
            
        } catch (error) {
            this.log(`Error during recording cleanup: ${error.message}`, 'error');
        } finally {
            // Always release wake lock and reset UI state
            this.releaseWakeLock();
            this.updateUI();
        }
    }

    private async toggleRecording(): Promise<void> {
        if (!this.state.recording) {
            await this.startRecording();
        } else {
            await this.stopRecording();
        }
    }

    private updateUI(): void {
        if (this.state.recording) {
            this.ui.recordButton.textContent = 'Stop Recording';
            this.ui.recordButton.classList.add('red-button');
            this.ui.progressBarContainer.style.display = 'none';
            this.ui.audioSource.style.display = 'none';  // Hide mic selector while recording
            this.ui.animationContainer.style.display = 'block';
            this.ui.summaryElement.style.display = 'none';
            this.ui.copyButton.style.display = 'none';
            this.ui.copySessionButton.style.display = 'none';
            this.ui.copyTranscriptionButton.style.display = 'none';
            this.ui.retryButton.style.display = 'none';
            this.ui.promptSection.style.display = 'none';
            
            // Show and position the audio visualizer
            this.ui.audioVisualizer.style.display = 'block';
            this.ui.audioVisualizer.style.margin = '10px auto';
        } else {
            this.ui.recordButton.textContent = 'Start Recording';
            this.ui.recordButton.classList.remove('btn-secondary', 'red-button');
            this.ui.recordButton.style.setProperty('background-color', 'var(--primary-color)');
            this.ui.audioSource.style.display = 'block';  // Show mic selector when not recording
            this.ui.timerElement.textContent = '00:00:00';
            
            // Hide the audio visualizer
            this.ui.audioVisualizer.style.display = 'none';
            
            // Clean up audio context if it exists
            if (this.audioContext) {
                this.audioContext.suspend();
            }
        }

        this.updateTimerDisplay();
    }

    private startRecordingTimeout(): void {
        setTimeout(() => {
            if (this.state.recording && this.state.seconds >= this.maxRecordingDuration) {
                this.stopRecording();
                this.handleError('Maximum recording duration reached (2 hours)');
            }
        }, this.maxRecordingDuration * 1000);
    }

    private startTimer(): void {
        this.state.seconds = 0;
        this.intervals.timer = window.setInterval(() => {
            this.state.seconds++;
            this.updateTimerDisplay();
        }, 1000);
    }

    private stopTimer(): void {
        if (this.intervals.timer) {
            clearInterval(this.intervals.timer);
            delete this.intervals.timer;
        }
    }

    private updateTimerDisplay(): void {
        const hours = Math.floor(this.state.seconds / 3600);
        const minutes = Math.floor((this.state.seconds % 3600) / 60);
        const seconds = this.state.seconds % 60;
        this.ui.timerElement.textContent = 
            `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }

    private async resetState(): Promise<void> {
        try {
            // Reset recording state
            this.state.currentSessionId = null;
            this.state.recording = false;
            this.state.chunkSet = [];
            this.state.chunkNumber = 0;
            this.state.mediaRecorder = null;
            if (this.state.audioStream) {
                this.state.audioStream.getTracks().forEach(track => track.stop());
            }
            this.state.audioStream = null;
            this.state.isRecording = false;
            this.state.seconds = 0;

            // Clear intervals
            if (this.intervals.timer) clearInterval(this.intervals.timer);
            if (this.intervals.heartbeat) clearInterval(this.intervals.heartbeat);
            if (this.intervals.summaryPolling) clearInterval(this.intervals.summaryPolling);
            if (this.intervals.progressUpdater) clearInterval(this.intervals.progressUpdater);
            this.intervals = {};

            // Reset UI elements
            this.ui.recordButton.textContent = 'Start Recording';
            this.ui.recordButton.style.backgroundColor = 'var(--primary-color)';
            this.ui.timerElement.textContent = '00:00:00';
            this.ui.progressBarContainer.style.display = 'none';
            this.ui.progressBar.style.width = '0%';
            this.ui.animationContainer.style.display = 'none';
            this.ui.summaryElement.textContent = '';
            this.ui.copyButton.style.display = 'none';
            this.ui.copySessionButton.style.display = 'none';
            this.ui.copyTranscriptionButton.style.display = 'none';
            this.ui.retryButton.style.display = 'none';
            this.ui.statusElement.textContent = '';

            // Reset and hide prompt section
            this.ui.promptSection.style.display = 'none';
            this.ui.promptInput.value = '';
            if (this.ui.promptResponses) {
                this.ui.promptResponses.innerHTML = '';
            }

            // Reset wake lock
            if (this.wakeLock !== null) {
                await this.wakeLock.release();
                this.wakeLock = null;
            }

            // Get new session ID
            const response = await fetch('/api/start_session', {
                method: 'POST'
            });
            
            if (!response.ok) {
                throw new Error('Failed to get session ID');
            }
            
            const data = await response.json();
            this.state.currentSessionId = data.session_id;
            console.log('New session ID acquired:', this.state.currentSessionId);

        } catch (error) {
            console.error('Failed to reset state:', error);
            this.handleError('Failed to initialize recording session. Please try again.');
            throw error;
        }
    }

    private async sendChunk(chunk: Blob, chunkNumber: number, isLastChunk: boolean): Promise<void> {
        const maxRetries = 3;
        let lastError: Error | null = null;
        
        for (let attempt = 0; attempt < maxRetries; attempt++) {
            try {
                if (!this.state.currentSessionId) {
                    throw new Error("No active recording session");
                }

                this.log(`Sending chunk ${chunkNumber}, attempt ${attempt + 1}/${maxRetries}`);

                const mimeType = this.supportedMediaFormat || 'video/webm';
                const fileExtension = mimeType.includes('webm') ? 'webm' : 'm4a';
                const fileName = `chunk_${chunkNumber}.${fileExtension}`;

                const formData = new FormData();
                formData.append('file', chunk, fileName);
                formData.append('session_id', this.state.currentSessionId);
                formData.append('chunk_number', chunkNumber.toString());
                formData.append('is_last_chunk', isLastChunk.toString());
                formData.append('chunk_size', chunk.size.toString());
                formData.append('mime_type', mimeType);

                const response = await fetch('/api/upload_chunk', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json'
                    },
                    body: formData,
                    // Add timeout to prevent hanging
                    signal: AbortSignal.timeout(10000)
                });

                if (!response.ok) {
                    const errorText = await response.text();
                    throw new Error(`HTTP error! status: ${response.status}, error: ${errorText}`);
                }

                const result = await response.json();
                this.log(`Chunk ${chunkNumber} sent successfully: ${JSON.stringify(result)}`);

                if (isLastChunk) {
                    this.log('Last chunk sent, waiting for processing to start...');
                    await new Promise(resolve => setTimeout(resolve, 1000));
                }
                
                // Success, exit retry loop
                return;
                
            } catch (error) {
                lastError = error as Error;
                this.log(`Error sending chunk (attempt ${attempt + 1}): ${error.message}`, 'error');
                
                // On network error, wait longer between retries
                if (error instanceof TypeError && error.message.includes('fetch')) {
                    await new Promise(resolve => setTimeout(resolve, 2000 * (attempt + 1)));
                }
            }
        }

        // If we get here, all retries failed
        this.handleError('Failed to upload audio chunk after multiple attempts. Please try again.');
        throw lastError;
    }

    private async fetchSummary(): Promise<void> {
        const maxAttempts = 40;
        const delayBetweenAttempts = 3000;

        for (let attempt = 1; attempt <= maxAttempts; attempt++) {
            this.log(`Fetching summary attempt ${attempt} of ${maxAttempts}`);
            try {
                const response = await fetch(`/api/get_summary/${this.state.currentSessionId}`, {
                    method: 'GET'
                });
                
                // Try to get response text for better error logging
                let responseText = '';
                try {
                    responseText = await response.text();
                } catch (e) {
                    responseText = 'Could not get response text';
                }
                
                this.log(`Fetch response status: ${response.status}, text: ${responseText}`);

                if (response.status === 404) {
                    this.log(`Summary not ready yet (attempt ${attempt})`);
                    if (attempt < maxAttempts) {
                        await new Promise(resolve => setTimeout(resolve, delayBetweenAttempts));
                        continue;
                    }
                    throw new Error('Summary generation timed out');
                }

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}, response: ${responseText}`);
                }

                // Parse the response text as JSON
                const data = JSON.parse(responseText);
                this.log(`Received response: ${JSON.stringify(data)}`);

                if (data.status === 'complete' && data.summary) {
                    // Set progress to 100% and complete the animation
                    this.ui.progressBar.style.width = '100%';
                    this.ui.statusElement.textContent = 'Complete';
                    await new Promise(resolve => setTimeout(resolve, 1000));
                    
                    // Hide progress elements
                    this.ui.progressBarContainer.style.display = 'none';
                    this.ui.statusElement.style.display = 'none';
                    this.ui.animationContainer.style.display = 'none';
                    
                    // Display the summary
                    this.displaySummary(data.summary);
                    return;
                } else if (data.status === 'processing') {
                    this.log('Summary still processing...');
                    await new Promise(resolve => setTimeout(resolve, delayBetweenAttempts));
                    continue;
                } else if (data.status === 'error') {
                    throw new Error(data.error || 'Unknown error occurred');
                }
            } catch (error) {
                if (attempt === maxAttempts) {
                    console.error('Failed to fetch summary:', error);
                    this.handleError('Failed to get meeting summary. Please try again.');
                    throw error;
                }
                this.log(`Error fetching summary on attempt ${attempt}: ${error}`);
                await new Promise(resolve => setTimeout(resolve, delayBetweenAttempts));
            }
        }
    }

    private formatText(text: string): string {
        if (!text) return '';
        
        try {
            // Normalize Unicode characters (e.g., different types of quotes, dashes)
            text = text.normalize('NFKC');
            
            // Replace problematic characters with safe alternatives
            const replacements: [RegExp | string, string][] = [
                [/[\u2018\u2019]/g, "'"],    // Smart single quotes
                [/[\u201C\u201D]/g, '"'],    // Smart double quotes
                [/[\u2013\u2014]/g, '-'],    // En and em dashes
                [/\u2026/g, '...'],          // Ellipsis
                [/\u00A0/g, ' '],            // Non-breaking space
                [/\r\n/g, '\n'],             // Normalize line endings
                [/\n{3,}/g, '\n\n'],         // Limit consecutive newlines
                [/[^\S\r\n]+/g, ' '],        // Normalize multiple spaces (preserve newlines)
                [/\n/g, '<br>']              // Convert newlines to HTML line breaks
            ];
            
            replacements.forEach(([pattern, replacement]) => {
                text = text.replace(pattern, replacement);
            });
            
            // Ensure proper spacing around punctuation
            text = text.replace(/([.!?])([A-Za-z])/g, '$1 $2');
            
            // Remove any null characters or other invisible control characters
            text = text.replace(/[\x00-\x1F\x7F-\x9F]/g, '');
            
            return text.trim();
        } catch (error) {
            console.error('Error formatting text:', error);
            // Return original text if formatting fails
            return text;
        }
    }

    private escapeHtml(text: string): string {
        if (!text) return '';
        
        try {
            const htmlEntities: Record<string, string> = {
                '&': '&amp;',
                '<': '&lt;',
                '>': '&gt;',
                '"': '&quot;',
                "'": '&#39;',
                '`': '&#x60;',
                '=': '&#x3D;'
            };
            
            return text.replace(/[&<>"'`=]/g, char => htmlEntities[char] || char);
        } catch (error) {
            console.error('Error escaping HTML:', error);
            // Return safely escaped minimal version if full escaping fails
            return text.replace(/[<>]/g, '');
        }
    }

    private markdownToHtml(markdown: string): string {
        if (!markdown) return '';
        
        try {
            // First sanitize the input
            markdown = this.formatText(markdown);
            
            // Handle line breaks first
            markdown = markdown.replace(/\n\n/g, '\n<br><br>\n');
            markdown = markdown.replace(/\n/g, '\n<br>\n');
            
            // Escape HTML in the text, but preserve our <br> tags
            markdown = this.escapeHtml(markdown)
                .replace(/&lt;br&gt;/g, '<br>');  // Restore <br> tags
            
            // Convert markdown to HTML with error handling for each pattern
            const patterns: [RegExp, string][] = [
                // Headers (only allow up to h3 for safety)
                [/^### (.*$)/gm, '<h3>$1</h3>'],
                [/^## (.*$)/gm, '<h3>$1</h3>'],
                [/^# (.*$)/gm, '<h3>$1</h3>'],
                
                // Bold
                [/\*\*(.*?)\*\*/g, '<strong>$1</strong>'],
                [/__([^_]+)__/g, '<strong>$1</strong>'],
                
                // Italic
                [/\*(.*?)\*/g, '<em>$1</em>'],
                [/_([^_]+)_/g, '<em>$1</em>'],
                
                // Lists
                [/^\s*[-*+]\s+(.*)$/gm, '<li>$1</li>'],
                
                // Links (only allow http/https)
                [/\[([^\]]+)\]\((https?:\/\/[^\)]+)\)/g, '<a href="$2" target="_blank" rel="noopener noreferrer">$1</a>']
            ];
            
            let html = markdown;
            patterns.forEach(([pattern, replacement]) => {
                html = html.replace(pattern, replacement);
            });
            
            return html;
        } catch (error) {
            console.error('Error converting markdown to HTML:', error);
            // Return safely escaped text if conversion fails
            return this.escapeHtml(markdown);
        }
    }

    private displaySummary(summary: string): void {
        this.ui.summaryElement.innerHTML = this.markdownToHtml(summary);
        (this.ui.summaryElement as any).dataset.originalMarkdown = summary;
        
        // Reset button state
        this.ui.recordButton.textContent = 'Start Recording';
        this.ui.recordButton.classList.remove('btn-secondary', 'red-button');
        this.ui.recordButton.classList.add('btn-primary');

        this.showCopyButton();
        this.ui.copySessionButton.style.display = 'block';
        this.ui.promptSection.style.display = 'block';
    }

    private handleError(message: string, showRetry: boolean = false): void {
        this.ui.summaryElement.innerHTML = `<div class="error">${message}</div>`;
        this.ui.retryButton.style.display = showRetry ? 'block' : 'none';
    }

    private async handleRetryClick(): Promise<void> {
        this.ui.retryButton.style.display = 'none';
        try {
            await this.resetState();
            this.ui.summaryElement.innerHTML = '';
        } catch (error) {
            this.handleError('Failed to reset state. Please refresh the page.');
        }
    }

    private async handlePrompt(): Promise<void> {
        const promptInput = this.ui.promptInput;
        const promptText = promptInput.value.trim();
        
        if (!promptText) return;
        
        try {
            this.setPromptLoading(true);
            const response = await this.sendPrompt(promptText);
            
            // Create new QA elements
            const responseElement = document.createElement('div');
            responseElement.className = 'prompt-response';
            
            const questionElement = document.createElement('div');
            questionElement.className = 'prompt-question';
            questionElement.textContent = promptText;
            
            const answerElement = document.createElement('div');
            answerElement.className = 'prompt-answer';
            answerElement.innerHTML = this.formatText(response);
            
            responseElement.appendChild(questionElement);
            responseElement.appendChild(answerElement);
            
            // Insert at the top of the list
            if (this.ui.promptResponses.firstChild) {
                this.ui.promptResponses.insertBefore(responseElement, this.ui.promptResponses.firstChild);
            } else {
                this.ui.promptResponses.appendChild(responseElement);
            }
            
            promptInput.value = '';
        } catch (error) {
            console.error('Error handling prompt:', error);
            this.handleError('Failed to get response for prompt');
        } finally {
            this.setPromptLoading(false);
        }
    }

    private async copyToClipboard(text: string): Promise<void> {
        console.log('Attempting to copy text:', text.substring(0, 50) + '...');
        console.log('Is iOS device:', this.isIOS);
        console.log('Is Safari:', this.isSafari);

        // For iOS Safari, try the modern API with a user gesture
        if (this.isIOS && this.isSafari) {
            try {
                // Ensure we have a clean string
                const cleanText = text.trim();
                console.log('Attempting iOS clipboard API with text length:', cleanText.length);
                
                // Try the modern API first
                if (navigator.clipboard && window.isSecureContext) {
                    await navigator.clipboard.writeText(cleanText);
                    console.log('Successfully used Clipboard API');
                    return;
                }
            } catch (err) {
                console.log('iOS Clipboard API failed:', err);
            }

            // iOS Safari fallback
            try {
                console.log('Using iOS fallback method');
                const textarea = document.createElement('textarea');
                textarea.value = text;
                
                // Critical iOS Safari styling
                textarea.style.position = 'fixed';
                textarea.style.left = '0';
                textarea.style.top = '0';
                textarea.style.opacity = '0';
                textarea.style.width = '100%';
                textarea.style.height = '100%';
                
                // Add to DOM
                document.body.appendChild(textarea);
                
                // Wait for DOM update
                await new Promise(resolve => setTimeout(resolve, 100));
                
                // iOS specific selection
                textarea.focus();
                textarea.select();
                textarea.setSelectionRange(0, textarea.value.length);
                
                console.log('Attempting execCommand copy');
                const successful = document.execCommand('copy');
                console.log('execCommand result:', successful);
                
                // Cleanup
                document.body.removeChild(textarea);
                
                if (successful) {
                    console.log('Successfully copied using fallback');
                    return;
                }
                throw new Error('execCommand copy failed');
            } catch (err) {
                console.error('iOS fallback failed:', err);
                throw err;
            }
        }

        // Non-iOS devices or if iOS methods fail
        try {
            await navigator.clipboard.writeText(text);
            console.log('Successfully used standard Clipboard API');
        } catch (err) {
            console.error('Standard clipboard method failed:', err);
            throw err;
        }
    }

    private async handleCopyTranscription(): Promise<void> {
        if (this.state.currentSessionId) {
            try {
                console.log('Fetching transcription for session:', this.state.currentSessionId);
                const response = await fetch(`/api/archived-session/${this.state.currentSessionId}`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                    },
                });

                if (!response.ok) {
                    console.error('Transcription fetch failed:', response.status, response.statusText);
                    const errorText = await response.text();
                    console.error('Error response:', errorText);
                    throw new Error(`Failed to fetch transcription: ${response.status} ${response.statusText}`);
                }

                const data = await response.json();
                console.log('Received transcription data:', {
                    status: response.status,
                    contentType: response.headers.get('content-type'),
                    dataKeys: Object.keys(data),
                    transcriptionLength: data.transcription?.length || 0,
                    transcriptionPreview: data.transcription?.substring(0, 100) + '...',
                    hasTranscription: !!data.transcription
                });

                if (!data.transcription) {
                    console.error('No transcription in response. Full response:', data);
                    throw new Error('No transcription found in response');
                }

                await this.copyToClipboard(data.transcription);
                
                const originalText = this.ui.copyTranscriptionButton.innerHTML;
                this.ui.copyTranscriptionButton.innerHTML = '<span class="icon">✅</span> Copied!';
                setTimeout(() => {
                    this.ui.copyTranscriptionButton.innerHTML = '<span class="icon">📝</span> Copy Transcription';
                }, 2000);
            } catch (err) {
                console.error('Failed to copy transcription:', err);
                this.ui.statusElement.textContent = 'Failed to copy transcription to clipboard';
                // Show error to user
                alert('Failed to copy transcription. Please try again.');
            }
        }
    }

    private async handleCopyClick(): Promise<void> {
        try {
            await this.copyToClipboard(this.ui.summaryElement.textContent || '');
            const originalText = this.ui.copyButton.innerHTML;
            this.ui.copyButton.innerHTML = '<span class="icon">✅</span> Copied!';
            setTimeout(() => {
                this.ui.copyButton.innerHTML = '<span class="icon">📋</span> Copy Summary';
            }, 2000);
        } catch (error) {
            console.error('Failed to copy text:', error);
        }
    }

    private async handleCopySessionId(): Promise<void> {
        if (this.state.currentSessionId) {
            try {
                await this.copyToClipboard(this.state.currentSessionId);
                console.log('Session ID copied to clipboard');
                const originalText = this.ui.copySessionButton.innerHTML;
                this.ui.copySessionButton.innerHTML = '<span class="icon">✅</span> Copied!';
                setTimeout(() => {
                    this.ui.copySessionButton.innerHTML = '<span class="icon">🔗</span> Copy Session ID';
                }, 2000);
            } catch (err) {
                console.error('Failed to copy session ID:', err);
                this.displayError('Failed to copy session ID to clipboard');
            }
        }
    }

    private showCopyButton(): void {
        this.ui.copyButton.style.display = 'block';
        this.ui.copyButton.innerHTML = '<span class="icon">📋</span> Copy Summary';
        this.ui.copySessionButton.style.display = 'block';
        this.ui.copySessionButton.innerHTML = '<span class="icon">🔗</span> Copy Session ID';
        this.ui.copyTranscriptionButton.style.display = 'block';
        this.ui.copyTranscriptionButton.innerHTML = '<span class="icon">📝</span> Copy Transcription';
    }

    private convertToSlackMarkdown(text: string): string {
        // Convert HTML to Slack markdown
        text = text.replace(/<h3><strong>(.*?)<\/strong><\/h3>/g, '*$1*');
        text = text.replace(/<strong>(.*?)<\/strong>/g, '*$1*');
        text = text.replace(/<br>/g, '\n');
        return text;
    }

    private displayError(message: string): void {
        this.ui.summaryElement.innerHTML = `<div class="error">${message}</div>`;
    }

    // Utility methods
    private log(message: string, level: 'info' | 'error' | 'warn' = 'info'): void {
        console[level](`[${new Date().toISOString()}] ${message}`);
    }

    private parseMarkdown(text: string): string {
        // H3 headers (bold)
        text = text.replace(/^###\s+(.*$)/gm, '<h3><strong>$1</strong></h3>');
        // Bold
        text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
        // Ensure newlines are preserved
        text = text.replace(/\n/g, '<br>');
        return text;
    }

    private async sendPrompt(promptText: string): Promise<string> {
        if (!this.state.currentSessionId) {
            throw new Error('No active session');
        }

        try {
            this.setPromptLoading(true);
            const response = await fetch('/api/prompt', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    session_id: this.state.currentSessionId,
                    prompt: promptText,
                    use_archived: true  // Signal to use archived session data
                })
            });

            if (!response.ok) {
                const errorText = await response.text();
                console.error('Prompt API error:', response.status, errorText);
                throw new Error(`Failed to send prompt: ${response.status} ${response.statusText}`);
            }

            const data = await response.json();
            return data.response;
        } finally {
            this.setPromptLoading(false);
        }
    }

    private setPromptLoading(isLoading: boolean): void {
        if (isLoading) {
            this.ui.sendPromptButton.innerHTML = '<div class="send-button-content"><div class="spinner"></div></div>';
            this.ui.sendPromptButton.disabled = true;
            this.ui.promptInput.disabled = true;
        } else {
            this.ui.sendPromptButton.innerHTML = 'Send';
            this.ui.sendPromptButton.disabled = false;
            this.ui.promptInput.disabled = false;
        }
    }

    private startProgressUpdates(): ProgressAnimation {
        const progressBarContainer = this.ui.progressBarContainer;
        const progressBar = this.ui.progressBar;
        const statusElement = this.ui.statusElement;
        const animationContainer = this.ui.animationContainer;
        
        // Show UI elements immediately
        progressBarContainer.style.display = 'block';
        statusElement.style.display = 'block';
        animationContainer.style.display = 'block';
        progressBar.style.width = '0%';
        statusElement.textContent = 'Processing audio...';
        
        let animationRunning = true;
        let isComplete = false;
        let currentFrame = 0;

        const updateAnimation = () => {
            if (!animationRunning) return;
            animationContainer.textContent = this.animationFrames[currentFrame];
            currentFrame = (currentFrame + 1) % this.animationFrames.length;
            setTimeout(updateAnimation, 200);
        };

        const setProgress = (percent: number) => {
            if (!isComplete) {
                progressBar.style.width = `${percent}%`;
            }
        };

        const setStatus = (text: string) => {
            if (!isComplete) {
                statusElement.textContent = text;
            }
        };

        const wait = async (ms: number) => {
            await new Promise(resolve => setTimeout(resolve, ms));
        };

        const animateProgress = async (start: number, end: number, duration: number, status: string) => {
            const startTime = Date.now();
            while (!isComplete && Date.now() - startTime < duration) {
                const elapsedTime = Date.now() - startTime;
                const progress = start + (end - start) * (elapsedTime / duration);
                setProgress(progress);
                setStatus(status);
                await new Promise(resolve => setTimeout(resolve, 50));
            }
            if (!isComplete) {
                setProgress(end);
            }
        };

        const runAnimation = async () => {
            updateAnimation();
            while (animationRunning && !isComplete) {
                await animateProgress(20, 40, 1500, 'Processing audio');
                if (isComplete) break;
                await wait(500);
                await animateProgress(40, 70, 2500, 'Transcribing');
                if (isComplete) break;
                await wait(4000);
                await animateProgress(70, 80, 4000, 'Generating summary');
                if (isComplete) break;
                setStatus('Waiting for summary...');
                await wait(30000);
            }
        };

        runAnimation();

        return {
            complete: async () => {
                isComplete = true;
                animationRunning = false;
                progressBar.style.width = '100%';
                statusElement.textContent = 'Complete';
                await wait(1000);
                progressBarContainer.style.display = 'none';
                statusElement.style.display = 'none';
                animationContainer.style.display = 'none';
            }
        };
    }

    private async acquireWakeLock(): Promise<void> {
        this.log("Attempting to acquire wake lock...");
        if ('wakeLock' in navigator) {
            try {
                this.wakeLock = await (navigator as any).wakeLock.request('screen');
                this.log('Wake Lock is active');
            } catch (err) {
                console.error(`${err.name}, ${err.message}`);
            }
        } else {
            console.warn('Wake Lock API not supported');
        }
    }

    private async releaseWakeLock(): Promise<void> {
        if (this.wakeLock !== null) {
            try {
                await this.wakeLock.release();
                this.wakeLock = null;
                this.log('Wake Lock is released');
            } catch (err) {
                console.error(`${err.name}, ${err.message}`);
            }
        }
    }

    private async setupAudioVisualization(stream: MediaStream): Promise<void> {
        if (!this.audioContext) {
            this.audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
        }

        // Create analyzer node
        this.analyser = this.audioContext.createAnalyser();
        this.analyser.fftSize = 32; // Smaller size for basic volume detection
        const bufferLength = this.analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        // Connect audio source to analyzer
        const source = this.audioContext.createMediaStreamSource(stream);
        source.connect(this.analyser);

        // Set up canvas
        const canvas = this.ui.audioVisualizer;
        const canvasCtx = canvas.getContext('2d')!;

        // Ensure canvas is visible
        canvas.style.display = 'block';
        
        // Set canvas size
        canvas.width = 200;  // Fixed width
        canvas.height = 30;  // Fixed height

        const draw = () => {
            this.animationFrameId = requestAnimationFrame(draw);

            this.analyser!.getByteFrequencyData(dataArray);

            // Calculate average volume and boost it by 100% (halving the effective range)
            const average = dataArray.reduce((a, b) => a + b) / dataArray.length;
            const volume = Math.min(1, (average / 255.0) * 2); // Normalize to 0-1 with 100% boost

            // Clear canvas with transparent background
            canvasCtx.clearRect(0, 0, canvas.width, canvas.height);

            // Draw volume bar
            const barHeight = 6; // Fixed height for the bar
            const y = (canvas.height - barHeight) / 2; // Center the bar vertically
            const width = volume * canvas.width;

            // Draw volume bar with solid fluorescent green
            canvasCtx.fillStyle = '#39FF14'; // Fluorescent green
            canvasCtx.fillRect(0, y, width, barHeight);
        };

        // Start the visualization
        draw();
    }

    private stopAudioVisualization(): void {
        if (this.animationFrameId !== null) {
            cancelAnimationFrame(this.animationFrameId);
            this.animationFrameId = null;
        }

        if (this.audioContext) {
            this.audioContext.suspend();
        }

        const canvas = this.ui.audioVisualizer;
        const canvasCtx = canvas.getContext('2d')!;
        canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
        canvas.style.display = 'none';
    }

    private async previewAudioInput(): Promise<void> {
        // Stop any existing preview
        if (this.state.audioStream) {
            this.state.audioStream.getTracks().forEach(track => track.stop());
            this.state.audioStream = null;
        }
        this.stopAudioVisualization();

        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                audio: {
                    deviceId: this.ui.audioSource.value ? { exact: this.ui.audioSource.value } : undefined
                }
            });
            
            // Store the preview stream
            this.state.audioStream = stream;
            
            // Start visualization
            await this.setupAudioVisualization(stream);
        } catch (error) {
            console.error('Error starting audio preview:', error);
            // Clear the visualization
            this.stopAudioVisualization();
        }
    }

    private isMobileDevice(): boolean {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    private showBrowserWarning(): void {
        // Don't show warning if we're loading a session (URL has a UUID)
        const pathParts = window.location.pathname.split('/');
        const sessionId = pathParts[1];
        if (sessionId && sessionId.length > 0) {
            return;
        }

        if (!this.isMobileDevice()) {
            const popup = document.createElement('div');
            popup.className = 'browser-warning-popup';
            
            popup.innerHTML = `
                <p><strong>IMPORTANT:</strong> Use this tool on <strong>mobile</strong> to capture all audio, including video conference audio. When using this tool on a <strong>computer</strong> - It can only hear other people in the <strong>room</strong>, not people on a video call due to the computer's internal noise cancellation.</p>
                <button>Understood</button>
            `;

            const overlay = document.createElement('div');
            overlay.className = 'browser-warning-overlay';
            
            document.body.appendChild(overlay);
            document.body.appendChild(popup);

            const button = popup.querySelector('button');
            if (button) {
                button.onclick = () => {
                    popup.remove();
                    overlay.remove();
                };
            }
        }
    }

    private async initializeApp(): Promise<void> {
        try {
            this.bindUIElements();
            this.setupEventListeners();

            // Check for session ID in URL path before loading audio devices
            const pathParts = window.location.pathname.split('/');
            const sessionId = pathParts[1];
            
            if (sessionId && sessionId.length > 0) {
                // Remove any trailing slashes
                const cleanSessionId = sessionId.replace(/\/$/, '');
                await this.loadSessionFromId(cleanSessionId);
            } else {
                // Only load audio devices and show browser warning if we're not loading a session
                await this.loadAudioDevices();
                this.showBrowserWarning();
            }
            
            this.updateUI();
        } catch (error) {
            console.error('Error initializing app:', error);
        }
    }
}

// Initialize the recorder
const recorder = new MeetingRecorder();